<template>
<!-- 共通部分、app.vueにv-appとv-containerなどが既にある -->
  <v-main class="top-a" :style="(init.preview == 1) ? 'padding-bottom:70px;' : ''">
    <component :is="'style'" type="text/css" v-if="init.top_background_image">
      .wrap::before {
        background-image: url({{init.top_background_image}}) !important;
        background-size: cover !important;
        content: "";
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100vh;
      }
    </component>
    <div class="wrap" style="min-height:100vh;">

    <!-- logo -->
   <!-- <v-row align-content="center" justify="center" no-gutters class="logo" :style="{
       backgroundImage: 'url(' + init.top_background_image + ') !important',
       backgroundSize: 'cover !important'
   }">
     <img v-if="init.top_logo_image" :src="init.top_logo_image" style="width:auto; max-width:100%; padding:0.5em" />
     <span v-else style="font-weight: bold; font-size: 22px;">{{init.shop_name}}</span>
   </v-row> -->

    <!-- background & scroll -->
    <div class="body">
      <!-- carousel -->
      <v-row align-content="start" justify="center" no-gutters class="mb-5" v-show="banners && banners.length > 0">
        <v-carousel v-model="model" height="163" :show-arrows="false" :cycle="true" :continuous="true" hide-delimiter-background>

          <div v-for="(item, i) in banners" :key="i">
            <v-carousel-item :key="i" :src="item.image_url" @click.stop="fromBannerTo(item.is_external, item.external_url, item.banner_title, item.banner_id, item.start_browser_flg)"></v-carousel-item>
          </div>
        </v-carousel>
      </v-row>

      <!-- user rank -->
      <v-row v-if="rank != ''" class="top_rank" align-content="start" no-gutters @click.stop="toDoublePointScreen()">
        <v-col :cols=2 class="rank_image_col text-center">
          <img :src="rank_img_path" class="rank_image" />
        </v-col>
        <v-col :cols=6 class="rank_icon_col">
          <div class="rank_text">{{rank_text}}</div>
          <br />
          <span v-if="rankmax_flg == 0" class="rank_remaining">次のランクまで{{rank_remaining}}P</span>
          <span v-if="rankmax_flg == 1" class="rank_remaining">最上ランクです。</span>
        </v-col>
        <v-col :cols=4 class="available_point_col">
          <v-row no-gutters style="padding-top:0.4em; padding-right:0.7em;">
            <v-col>
              <span class="available_point_1">利用可能ポイント</span>
            </v-col>
          </v-row>
          <v-row no-gutters style="padding-right:0.7em; margin-top:0.25em">
            <v-col>
              <span class="available_point_2">{{point}}</span>
              <span class="available_point_3"> P</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- menu list -->
      <v-row align-content="start" justify="center" no-gutters v-for="(item, i) in menus" :key="i">
        <v-card class="card mt-5" outlined :img="item.background_image_url" @click.stop="NextRouting(item.external_url, item.feature_id, item.screen_id, item.transition_type, item.top_type, item.cassette_id, item.transition_info, item.title)">
          <span class="cardTitle" v-if="item.title_view_flg" :style="{color: item.title_color}">{{item.title}}</span>
          <span class="cardDescription" :style="{color: item.sub_title_color}">{{item.sub_title}}</span>
          <v-btn v-if="item.is_more_hidden == 0" rounded elevation="0" small class="cardButton maincolor-bg" :style="{color: item.more_button_color + ' !important'}" @click.stop="NextRouting(item.external_url, item.feature_id, item.screen_id, item.transition_type, item.top_type, item.cassette_id, item.transition_info, item.title)">
            MORE
          </v-btn>
        </v-card>
      </v-row>

      <noticeDialogue ref="noticeDialogue" :item="one_time_notice"></noticeDialogue>

      <!-- やまや会員統合通知ダイアログ -->
      <v-dialog v-model="accountIntegratedNoticeDialog" persistent>
        <v-card class="px-6 pt-5">
          <div class="text-center">
            <p class="fontsize18">アプリのみお使いの方も同じIDとパスワードでオンラインショップでのお買い物も出来ます。</p>
            <div class="">
              <v-btn rounded depressed class="caption font-weight-black mb-10 w80" style="background-color:#EFEFEF;" @click.native="closeAccountIntegratedNoticeDialog()">閉じる</v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>

      <!-- お気に入り登録ダイアログ -->
      <v-dialog v-model="favoritePromptDialog" persistent>
        <v-card class="p-3" style="border-radius:20px; border: solid 2px #E90606">
          <div class="px-3 py-5 text-center">
            <h3 class="mt-2 font-weight-bold ribbon1">★をタップして店舗を</h3>
            <h3 class="mb-2 font-weight-bold ribbon1">フォロー！</h3>
            <v-row>
              <v-col cols="12">
                <img class="photo" src="@/assets/how-to-add-to-favorites.png" width="95%" style="border-radius:10px; border: solid 1px #E90606"/>
                <h3>お気に入り登録をすると店舗限定</h3>
                <h3>のクーポンやおトクな情報を</h3>
                <h3>ゲットできます！</h3>
              </v-col>
            </v-row>
            <div class="pt-7">
              <div>
                <v-btn rounded class="maincolor-bg default_button w80" @click.stop="showShopListPage()">
                  登録する
                </v-btn>
              </div>
              <div class="pt-4">
                <v-btn rounded depressed class="caption font-weight-black w80" style="background-color:#EFEFEF;" @click.native="closeFavoritePromptDialog()">
                  あとで登録する
                </v-btn>
              </div>
            </div>

          </div>
        </v-card>
      </v-dialog>
    </div>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>

    <GlobalFooter v-if="init.preview == 1" :menus="init.menu_data" />

  </v-main>
</template>

<script>

import noticeDialogue from "@/components/notice_dialog.vue"
import GlobalFooter from "@/components/global_footer.vue"
import obi from '@/assets/obi.png';
import rankIconBronze from '@/assets/top_rank/icon01_bronze.png';
import rankIconSilver from '@/assets/top_rank/icon02_silver.png';
import rankIconGold from '@/assets/top_rank/icon03_gold.png';
export default {
  components: {
      noticeDialogue,
      GlobalFooter,
  },
  data () {
    return {
      init: [],
      model: 0,
      banners: [],
      menus: [],
      one_time_notice: {},
      badgeParams: {},
      rank: "",
      rank_text: "",
      point: 0,
      rank_remaining: 0,
      obi,
      rank_image_path: "",
      rank_icons: [ rankIconBronze, rankIconSilver, rankIconGold ],
      rank_icon_col_css_class: [
        "available_point_rank_none",
        "available_point_rank_bronze",
        "available_point_rank_silver",
        "available_point_rank_gold",
      ],
      rankmax_flg: 0,
      loading: true,

      accountIntegratedNoticeDialog: false,
      favoritePromptDialog : false,
    }
  },
  async created () {
    //createdで、localStorageから全ての保存情報を取得しinitに格納
    this.init = this.storageGet('*')

    try {
      try{
        var banners_req = { params:{
          'sid': this.init.sid,
          'service_cd': this.init.service_cd,
          'device_uid': this.init.device_uid,
          'device_id': this.init.device_id,
          'os': this.init.os,
          'key': this.init.key,
          'preview': this.$route.query.preview, //isPreview Getパラメータがある場合は、APIにisPreviewを渡す
        }}
        var res = await this.apiCallIfUnauthorizedReturnsErrorCode('/user/banners', banners_req, 'get');
        if (!res) return false;
        this.$set(this, 'banners', res.banners);

        var menus_req = { params:{
          'sid': this.init.sid,
          'service_cd': this.init.service_cd,
          'device_uid': this.init.device_uid,
          'device_id': this.init.device_id,
          'os': this.init.os,
          'key': this.init.key,
          'preview': this.$route.query.preview, //isPreview Getパラメータがある場合は、APIにisPreviewを渡す
        }}
        var menu_res = await this.apiCallIfUnauthorizedReturnsErrorCode('/user/topmenu/1', menus_req, 'get');
        if (!menu_res) return false;
        this.$set(this, 'menus', menu_res.menus);

        if(menu_res.one_time_notice != null){
          this.$set(this, 'one_time_notice', menu_res.one_time_notice);

          if (this.storageGet('top_one_time_notice_id') != menu_res.one_time_notice.id) {
            var objtoken = (JSON.stringify({
              'top_one_time_notice_id': menu_res.one_time_notice.id
            }))
            this.storageSave(objtoken)
            this.$refs.noticeDialogue.open()
          }
        }

        const yamaya_id = this.$route.query.yamaya_id
        const login_action = this.$route.query.login_action
        console.log('yamaya_id : ' + yamaya_id)
        console.log('login_action : ' + login_action)
        if (login_action) {
          this.storageSave(JSON.stringify({
            'device_uid': yamaya_id,
          }))

          // バッジをinitで再取得
          await this.initAfterNewAccountOrLogin()

          // お気に入り店舗が登録済みかをチェックする（本店以外）
          const shop_list_req = {
            params: {
              'sid': this.init.sid,
              'device_uid': yamaya_id,
              'device_id': this.init.device_id,
              'os': this.init.os,
              'key': this.init.key,
              'location': 0,
              'feature_id': 'top', // 必須パラメータだった。
            }
          }
          const shop_list_res = await this.apiCall('/user/shops', shop_list_req)

          // お気に入り登録を促すダイアログを表示するか
          if (shop_list_res.recommend_shop_list.length === 0) {
            await this.showFavoritePromptDialog()
          }
        }

        // ログイン状態の判別
        if (this.init.device_uid  == null || this.init.device_uid == "false") {
          return false;
        }
        let access_token_check_res = await this.apiCallCustomerPF('/access_token/check/' + this.init.device_uid, {});
        if (!access_token_check_res || access_token_check_res.status != "OK") {
          return false;
        }

        // ユーザ取得
        const user_req = {
          'yamaya_id': this.init.device_uid,
        }
        const user_res = await this.apiCallCustomerPF('/user/get', user_req)
        this.storageSave(JSON.stringify({
          'password_integration_status'   : user_res.user.password_integration_status,
          'integration_notification_status' : user_res.user.integration_notification_status,
        }))

        // 1:パスワード不一致なら
        if (user_res.user.password_integration_status === 1) {
          // やまやIDを取っておかないとECに渡せない
          this.clearUserAndTokenExcludingDeviceUid()
          this.requestToNativeToShowLogin()
          return
        }

        // 0:未通知なら
        if (user_res.user.integration_notification_status === 0) {
          await this.showAccountIntegratedNoticeDialog()
        }

        // ログイン状態であればポイント、ランクを取得
        let point_req = { params:{
          'sid': this.init.sid,
          'service_cd': this.init.service_cd,
          'device_uid': this.init.device_uid,
          'device_id': this.init.device_id,
          'os': this.init.os,
          'key': this.init.key,
          'preview': this.$route.query.preview, //isPreview Getパラメータがある場合は、APIにisPreviewを渡す
        }};
        let point_res = await this.apiCallIfUnauthorizedReturnsErrorCode('/point', point_req, 'get');
        if (!point_res) return false;

        this.point = point_res.point.toLocaleString();
        this.rank = point_res.rank;
        this.rank_text = point_res.rank_text;
        this.rank_remaining = point_res.rank_remaining.toLocaleString();
        this.rank_img_path = point_res.rank_img_path;

        if (point_res.rank != null) {
          if(point_res.rank == point_res.rank_detail.length){
            this.rankmax_flg = 1
          }else{
            this.rankmax_flg = 0
          }
        }

      } catch(e) {
        if (e.code == 401) {
          console.log("get point error: invalid token");
          return;
        }
        console.log(e);
        this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
      }
    } finally {
      this.loading = false;
    }

    var native_req = {};
    native_req['_vue_param'] = true;
    native_req['menu_data'] = this.init.menu_data;
    native_req['total_unread_count'] = this.init.total_unread_count;
    native_req['rank_img_path'] = this.rank_img_path;

    console.log(native_req)
    this.nativePost(native_req);
  },
  watch: { //init apiが読み込まれたか監視
    '$root.init_flg': 'fetchData',
  },
  methods: {
    fetchData(){ //init apiが読み込まれた場合initに値を入れる
        this.init = this.storageGet('*')

        //バッジ数をネイティブに送る
        this.badgeParams['_vue_param'] = true;
        this.badgeParams['menu_data'] = this.init.menu_data;
        this.badgeParams['total_unread_count'] = this.init.total_unread_count;
        this.badgeParams['rank_img_path'] = this.rank_img_path;

        //console.log(this.badgeParams)
        this.nativePost(this.badgeParams);
    },
    async NextRouting(destination, feature_id, screen_id, transition_type, top_type, cassette_id, transition_info, page_title){
      try{
        // プレビューの場合は通常遷移させる
        if (this.$route.query.preview) {
          return window.location.href = destination + '?sid=' + this.init.sid + '&preview=1';
        }

        //タップAPI
        var tap_req = {
          'sid': this.init.sid,
          'service_cd': this.init.service_cd,
          'device_uid': this.init.device_uid,
          'device_id': this.init.device_id,
          'os': this.init.os,
          'key': this.init.key,
          'top_type': top_type,
          'cassette_id': cassette_id,
          'transition_type': transition_type,
          'transition_info': transition_info,
        }
        var tap_res = await this.apiCallIfUnauthorizedReturnsErrorCode('/user/tap', tap_req, 'post');
        if (!tap_res) return false;

        if(feature_id == null){
          feature_id = 0
        }
        var tonative_req = {
          'destination': destination,
          'feature_id': feature_id,
          'screen_id': screen_id,
          'transition_type': transition_type,
          'page_title': page_title,
          'cassette_id': cassette_id,
          'top_type': top_type,
        };
        console.log(tonative_req);
        if (!(this.screenTransition(tonative_req))) {
          this.loading = false
          // 遷移失敗・遷移情報が変な時もエラー表示があった方が良い？
          this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
        }
      } catch(e) {
        console.log(e);
        this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
      }
    },
    fromBannerTo(is_external, external_url, page_title, banner_id, start_browser_flg){

      if(is_external == 1){
        var transition_type = 2
        if(start_browser_flg == 1){
          transition_type = 4
        }

        var tonative_req = {
          'destination': external_url,
          'feature_id': 0,
          'screen_id': 'banner',
          'transition_type': transition_type,
          'page_title': page_title,
          'cassette_id': banner_id,
          'top_type': 0,
        };
        console.log(tonative_req)

        if (!(this.screenTransition(tonative_req))) {
          this.loading = false
          this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
        }
      }
    },
    toDoublePointScreen(){
      var to_native_req = {
        destination: "https://" + process.env.VUE_APP_HOST_NAME + "/sp/wpoint/",
        feature_id: "point",
        screen_id: "point_b",
        transition_type: 2,
        page_title: "",
        top_type: 0,
      };
      console.log(to_native_req)

      if (!(this.screenTransition(to_native_req))) {
        this.loading = false
        this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
      }
    },
    toMOP(){
      var destination = 'https://' + process.env.VUE_APP_HOST_NAME + "/sp/mop"
      var feature_id = 1
      var screen_id = "top"
      var transition_type = 2
      var top_type = 1
      var cassette_id = 1
      var transition_info = "mop"
      var page_title = "モバイルオーダー"

      this.NextRouting(
        destination,
        feature_id,
        screen_id,
        transition_type,
        top_type,
        cassette_id,
        transition_info,
        page_title,
      )
    },

    showAccountIntegratedNoticeDialog() {
      this.accountIntegratedNoticeDialog = true
    },
    closeAccountIntegratedNoticeDialog() {
      // 閉じる操作をした時に更新とする
      const req = {
        'integration_notification_status' : 1, // 1:通知済
      }
      this.apiCallCustomerPF('/user-app-properties/update/' + this.init.device_uid, req);
      this.storageSave(JSON.stringify({
        'integration_notification_status' : 1, // 1:通知済
      }))
      this.accountIntegratedNoticeDialog = false
    },
    showFavoritePromptDialog() {
      this.favoritePromptDialog = true
    },
    showShopListPage() {
      this.requestToNativeToShowShopListPage()
      this.favoritePromptDialog = false
    },
    closeFavoritePromptDialog() {
      this.favoritePromptDialog = false
    },
  }
}
</script>

<style scoped>
.top-a {
  width: 375px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  font-family: "游ゴシック体";
}

.wrap::before {
  background: -moz-linear-gradient(-58deg, #CEE6FF, #F8CFF5);
  background: -webkit-linear-gradient(-58deg, #CEE6FF, #F8CFF5);
  background: linear-gradient(148deg, #CEE6FF, #F8CFF5);
}

.body {
  padding: 0 0 20px 0;
  overflow-y: auto;
  background-color: #fff;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.body::-webkit-scrollbar {
  display:none;
}
.top_rank {
  width: 356px;
  /* height: 70px; */
  margin: 20px 10px 0;
  color: #333333;
  background-color: #fff;
  border: 0 !important;
  border-radius: 15px !important;
  box-shadow: 0 2px 4px rgb(0 0 0 / 30%);
}
.rank_image_col {
  text-align: right;
  background-color: #F5EEE4;
  border-radius: 15px 0 0 15px;
}
.rank_image {
  margin-top: 0.2em;
  margin-bottom: -0.2rem;
  height: 2.3rem;
}
.rank_icon_col {
  line-height: 0em;
  background-color: #F4EFE1;
}
.rank_text {
  margin:1rem 0 0.875rem 0;
  color:#333;
  font-size:0.875rem;
  font-weight:bold;
  font-family:"Noto Sans JP", sans-serif;
}
.rank_icon {
  margin-top: 10px;
  /* padding-top: 30px; */
  width: 120px;
}
.rank_remaining {
  font-size: 0.6rem;
  margin-left: 0.05rem;
  font-family:"Noto Sans JP", sans-serif;
}
.available_point_col {
  background: #FF5045;
  color: #FFF;
  border-radius: 0px 15px 15px 0px !important;
  text-align: right;
  padding-right: 1em;
  line-height: 0em;
}
.available_point_rank_none {
  background: #eeeeee;
}
.available_point_rank_bronze {
  background: linear-gradient(45deg, #f9f9f9, #ac6b25);
}
.available_point_rank_silver {
  background: linear-gradient(45deg, #f9f9f9, #929292);
}
.available_point_rank_gold {
  background: linear-gradient(45deg, #fff, #d5bc8a);
}
.available_point_1 {
  font-size: 0.5rem;
  font-family:"Noto Sans JP", sans-serif;
}
.available_point_2 {
  font: bold 1.25rem 'Noto Sans JP';
  line-height: 1.5;
}
.available_point_3 {
  font: bold 0.75em 'Noto Sans JP';
}
.carouselCtrl {
  margin: 9px 0 0 0;
}
.carouselCtrl button {
  margin: 0 0 0 5px;
  color: #9D9D9D;
  text-shadow: 0 0 4px #0000004D;
}
.carouselCtrl .active {
  color: var(--main_color) !important;
}
.card {
  width: 356px;
  height: 180px;
  margin: 20px 10px 0;
  color: #333333;
  border: 0 !important;
}
.cardTitle {
  display: block;
  margin: 18px 16px;
  width: 146px;
  height: 12px;
  font: bold 10px 'Roboto';
}
.cardDescription {
  display: block;
  margin: 20px 16px;
  width: 146px;
  font: bold 14px '游ゴシック体';
}
.cardButton {
  position: absolute;
  right: 11px;
  bottom: 11px;
  padding: 0 18px !important;
  font: normal 12px 'Roboto';
  border: 2px solid #FFFFFF;
  background-color: #FF00B1;
}
/*.mop905020 {*/
/*  background:url("~@/assets/mop_905020_top_a.jpg") center center / cover no-repeat;*/
/*  border-radius: 15px !important;*/
/*}*/
</style>
