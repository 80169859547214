<template>
    <div>
        <v-dialog v-model="compdialog" persistent>
            <v-card class="ticket_dialogue">
                <h3 class="maincolor ticket_dialogue_title">{{item.title}}</h3>

                <v-img v-if="item.image_path" class="" height="224px" :src="item.image_path">
                </v-img>

                <div class="qrblc">
                    {{item.comment}}
                </div>
    
                <div class="text-center pt-1 pb-4" v-if="item.external_page == 1">
                    <v-btn rounded class="maincolor-bg default_button" @click.stop="NextRouting(item.url, item.transition_type)">詳細をみる</v-btn>
                </div>

                <div class="text-center py-2">
                    <v-btn small rounded class="graybtn" @click="close">閉じる</v-btn>
                </div>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    data: () => ({
        compdialog: false,
    }),

    methods: {
        NextRouting(destination, transition_type){
          try{

            var tonative_req = {
              'destination': destination,
              'feature_id': null,
              'screen_id': null,
              'transition_type': transition_type,
            };

            if (!(this.screenTransition(tonative_req))) {
              this.loading = false
              // 遷移失敗・遷移情報が変な時もエラー表示があった方が良い？
              this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
            }

          } catch(e) {
            this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
          }
        },
        open() {
          this.compdialog = true
        },
        close() {
          this.compdialog = false
        },
    },
}
</script>

<style scoped>
/* override */

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 15px;
}

.ticket_dialogue {
    padding: 16px;
}

.ticket_dialogue_title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding: 0 0 15px;
}

.ticket_dialogue_text {
    font-size: 13px;
    line-height: 20px;
    color: #333333;
}

.qrblc {
    width: 100%;
    margin: 0 auto 5px;
    padding: 10px;
    background-color: #F9F9F9;
}

.authcode_text {
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    margin: 0 0 0;
}

.v-label {
    font-size: 14px !important;
}

.authfield>>>label {
    font-size: 13px;
    text-align: center;
}

.authfield>>>input {
    text-align: center;
}
</style>
